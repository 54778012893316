<template>
	<v-container class="flex-column flex-nowrap justify-center align-self-start">
		<apollo-query :query="query" :update="data => data.company">
			<template v-slot="{ result: { loading, data } }">
				<a-company-card
					v-if="data"
					:name="data.name"
					:orgnr="data.orgnr"
					:telephone="data.tel"
					:email="data.email"
					:address="data.address"
					:media="data.logoUrl || 'https://source.unsplash.com/YXemfQiPR_E'"
					active
				/>

				<a-loading
					v-else
					:loading="loading"
					type="card, list-item-avatar-two-line@3"
				/>
			</template>
		</apollo-query>
	</v-container>
</template>

<script>
import ACompanyCard from '@/components/CompanyCard';
import ALoading from '@/components/Loading';
import { COMPANY } from '@/graphql';

export default {
	components: {
		ALoading,
		ACompanyCard,
	},
	data: () => ({
		query: COMPANY,
	}),
	metaInfo: {
		title: 'Kundtjänst',
	},
};
</script>

<style></style>
