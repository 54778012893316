<template>
	<apollo-query
		:query="query"
		:update="data => data.issueTypes[0]"
		:variables="{ input: { view: 'issues' } }"
	>
		<template v-slot="{ result: { data } }">
			<v-card v-if="data" width="100%">
				<v-img v-if="media" :src="media" contain class="white--text" />

				<v-list two-line subheader>
					<v-subheader class="text-xs-center">Kontaktinformation</v-subheader>

					<v-list-item @click="overlay = true">
						<v-list-item-icon class="mt-auto mb-auto ml-2 mr-6">
							<v-icon color="secondary">mdi-help</v-icon>
						</v-list-item-icon>

						<v-list-item-content>
							<v-list-item-title>Kontakta oss</v-list-item-title>
							<v-list-item-subtitle>
								Tryck här för att höra av dig direkt till oss
							</v-list-item-subtitle>
						</v-list-item-content>
					</v-list-item>

					<v-divider inset />

					<v-list-item
						v-if="telephone"
						:href="active ? 'tel:' + telephone : ''"
					>
						<v-list-item-icon class="mt-auto mb-auto ml-2 mr-6">
							<v-icon color="secondary">mdi-phone</v-icon>
						</v-list-item-icon>

						<v-list-item-content>
							<v-list-item-title>{{ telephone }}</v-list-item-title>
							<v-list-item-subtitle>Telefonnummer</v-list-item-subtitle>
						</v-list-item-content>
					</v-list-item>

					<v-divider inset />

					<v-list-item
						v-if="email"
						:href="active ? 'mailto:' + email : ''"
						target="_blank"
						rel="noopener noreferrer"
					>
						<v-list-item-icon class="mt-auto mb-auto ml-2 mr-6">
							<v-icon color="secondary">mdi-email</v-icon>
						</v-list-item-icon>

						<v-list-item-content>
							<v-list-item-title>{{ email }}</v-list-item-title>
							<v-list-item-subtitle>E-postadress</v-list-item-subtitle>
						</v-list-item-content>
					</v-list-item>

					<v-divider inset />

					<v-list-item v-if="address" :href="mapsUrl">
						<v-list-item-icon class="mt-auto mb-auto ml-2 mr-6">
							<v-icon color="secondary">mdi-map-marker</v-icon>
						</v-list-item-icon>

						<v-list-item-content>
							<v-list-item-title>{{ address.streetAddress }}</v-list-item-title>
							<v-list-item-subtitle>
								{{ address.areaCode }},
								{{ address.city }}
							</v-list-item-subtitle>
						</v-list-item-content>
					</v-list-item>
				</v-list>
			</v-card>

			<v-dialog v-model="overlay" max-width="800px">
				<a-create-issue-card :issueType="data" @close="overlay = false" />
			</v-dialog>
		</template>
	</apollo-query>
</template>

<script>
import { ISSUE_CREATION_TYPES } from '@/graphql';
import ACreateIssueCard from '@/components/CreateIssueCard';

export default {
	components: {
		ACreateIssueCard,
	},
	props: {
		active: {
			type: Boolean,
		},
		media: {
			type: String,
			default: '',
		},
		name: {
			type: String,
			required: true,
		},
		orgnr: {
			type: String,
			required: true,
		},
		telephone: {
			type: String,
			required: true,
		},
		email: {
			type: String,
			required: true,
		},
		address: {
			type: Object,
			required: true,
			validator(value) {
				const properties = ['streetAddress', 'areaCode', 'city'];
				return properties.every(property => property in value);
			},
		},
	},
	data: () => ({
		query: ISSUE_CREATION_TYPES,
		overlay: false,
	}),
	computed: {
		mapsUrl() {
			const areaCode = this.address.areaCode.replace(' ', '');
			return `http://maps.google.co.in/maps?q=${this.address.streetAddress},${areaCode},${this.address.city},Sweden`;
		},
	},
};
</script>

<style></style>
